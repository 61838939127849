import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Item } from './item';
import { UtilService} from "../util.service";
import { ProductsService } from '../../_services/index'
import { AdminAlertsService } from 'app/admin/alerts/admin-alerts.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { TREE_ACTIONS, ITreeOptions, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import { NavbarNode, User } from '../model/index';
import { Observable, Subscription } from 'rxjs';
import { NavbarService } from 'app/_services/navbar.service';
import { OtasErrors } from '../model/admin/ota.model';
import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MANUAL_GOOGLE_FB } from '../utils/statics/manual-links.static';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { OtasState } from 'app/states/otas/otas.state';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MenusState } from 'app/states/menus/menus.state';

@Component({
  selector: 'mh-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [AdminCustomerService],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output()
  menuStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('navbar_tree') treeComponent: TreeComponent;
  @ViewChild('StatusOTA') statusOTA: any;
  @ViewChild('StatusPMS') statusPMS: any;

  readonly EXTRA_SMALL_HEIGHT_RESOLUTION = 500;
  readonly SMALL_HEIGHT_RESOLUTION = 720;
  readonly MEDIUM_HEIGHT_RESOLUTION = 864;

  navItems: Item[] = [];
  products: any[] = [];
  currentUrl:string; //current section represent the Active Product
  productViews=[];
  productCategories=[];
  levelMenu
  nodes:NavbarNode[] = [];
  currentNode;
  isReady:boolean = false;
  util: UtilService;
  currentCustomerLogo;
  isLoading = false;
  isLoadingSubMenu = false;
  treeModel:TreeModel;
  is_super_admin: boolean = false;
  is_admin: boolean = false;
  currentUser: User;
  user_role;
  waitingIntegrationStatus:boolean = true;
  otasErrors: OtasErrors[] | null;
  showOtasErrors: boolean;
  showStates = true;
  showIntegrationState = false;
  isCollapsing = false;
  subscriptions: Subscription;
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree: TreeModel, node: TreeNode, $event) => {
          if(node.data.depth === 0 && node.isCollapsed) tree.collapseAll();
          if(node.data.depth === 1 && node.hasChildren && node.isCollapsed) {
            tree.nodes.forEach(elem => {
              elem.children.forEach(subelem => {
                if(node.data.id !== subelem.id) {
                  const treeNode = tree.getNodeById(subelem.id)
                  treeNode.collapseAll()
                }
              })
            })
          }

          if (node.data.depth === 2 && !node.hasChildren && tree.expandedNodes.length > 0) {
            tree.expandedNodes.forEach(subElement => {
              if (subElement.id === "/intercadena/online/cobertura" && node.id !== subElement.id) {
                subElement.collapseAll();
              }
            });
          }

          if (node.hasChildren){
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event)
          }else{
            this.currentUrl = node.data.id;
          }
        }
      }
    },
    allowDrag: (node) => {
      return false;
    },
    allowDrop: (node) => {
      return false;
    },
    animateExpand: true,
    animateSpeed: 100,
  }
  statusNavbar;
  showCollapseLabel = true;
  modal: NgbModalRef;
  modalPMS: NgbModalRef;
  displayStates = true;
  #_sideBarCollapsed = false;
  #_isMobile = false;

  set sideBarCollapsed(value) {
    this.#_sideBarCollapsed = value;
    const timeMs = this.#_sideBarCollapsed ? 100 : 400;
    setTimeout(() => {
      this.showCollapseLabel = !this.#_sideBarCollapsed;
    }, timeMs);
  }

  get sideBarCollapsed() {
    return this.#_sideBarCollapsed;
  }

  @Input() isMenuOpen;

  @Input() set isMobile (value: boolean) {
    this.#_isMobile = value;
    this.stateChange();
  }

  get isMobile() {
    return this.#_isMobile;
  }

  loadingOtas = true;
  chainOtasErrors = [];
  @Select(OtasState.chainOtasErrors) chainOtasErrors$: Observable<any>;
  @Select(CustomersState.currentCustomer) currentCustomer$: Observable<any>;
  @Select(MenusState.currentMenuSidebar) menuState$: Observable<any>;

  loadingOtaStatus = true;
  currentOta = '';

  constructor(
    private utilService: UtilService,
    private productsService: ProductsService,
    private adminAlertsService: AdminAlertsService,
    private notificationsService: NotificationsService,
    private navbarService: NavbarService,
    private adminCustomerService: AdminCustomerService,
    private modalService: NgbModal,
    private store: Store,
    private router: Router,
  ){ }

  handleIntegration($event) {
    if ($event) {
      this.utilService.customerChanged($event.customer);
      this.currentOta = $event.otaId === 31 ? 'facebook' 
      : this.currentOta = $event.otaId === 2 ? 'booking'
      : this.currentOta = $event.otaId === 1 ? 'tripadvisor' 
      : 'google';
      this.utilService
        .isLoadCustomerReady()
        .pipe(take(1))
        .subscribe(() => {
          setTimeout(() => {
            this.router.navigate([`/online/integraciones/${this.currentOta}`]);
          });
        });
    }
  }

  updateStatus(status) {
    this.navbarService.setStatus(status);
    if (this.sideBarCollapsed) {
      this.sideBarCollapsed = false;
      this.utilService.toggleNavbar(this.sideBarCollapsed);
    }
  }

  updateCustomerLogo(customer: any = null): void {
    if(customer) this.currentCustomerLogo = customer?.urlLogo;
    else {
      const currentCustomer = this.utilService.getCurrentHotel();
      this.currentCustomerLogo = currentCustomer.urlLogo;
    }
  }

  setCurrentNavbar(){
    if (this.currentUrl !== this.utilService.currentSection || this.statusNavbar === 0) {
      this.currentUrl = this.utilService.currentSection;
      this.treeModel = this.treeComponent.treeModel;
      if (this.statusNavbar === 3) {
        const firstNode = this.treeModel.getFirstRoot();
        firstNode.expand();
      }
    } else {
      this.treeModel = this.treeComponent.treeModel;
      if (this.statusNavbar === 3) {
        const firstNode = this.treeModel.getFirstRoot();
        firstNode.expand();
      } else {
        this.treeModel.expandAll();
      }
    }
  }

  herachyUrl(url){
    if(url){
      let lastIndex = url.lastIndexOf("/");
      let pattern = url.substring(0,lastIndex)+'/*';
      let actions   = ['edit/'];
      for(let action of actions){
        pattern = pattern.replace(action,'');
      }
      return pattern;
    }
    return '';
  }

  async ngOnInit() {
    this.util = this.utilService;
    this.updateCustomerLogo();
    this.statusNavbar = this.navbarService.getStatus();
    const status = this.navbarService.getStatus();
    await this.productsService.loadSidebarMenu(status);
    this.nodes = this.productsService.getNavbarItems();
    this.subscriptions = new Subscription();

    this.initListeners();
    this.currentUser = this.utilService.getCurrentUser();
    this.is_super_admin = this.currentUser.isSuperAdmin();
    this.is_admin = this.currentUser.admin;
    this.user_role = this.utilService.currentUser.role;
    this.loadOtasErrors();
    this.chainOtasErrors = this.store.selectSnapshot(OtasState.chainOtasErrors);    
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  loadMenu() {
    this.isLoading = true;
    this.nodes = null;
    setTimeout(async () => {
      const nodes = this.productsService.getNavbarItems();
      if (nodes === null || nodes === undefined) {
        this.productsService.loadSidebarMenu();
        this.nodes = await this.productsService.getNavbarItems();
      } else {
        this.nodes = nodes;
      }
      this.isLoading = false;
    }, 500);
  }

  isCurrent(item:NavbarNode){
    return item.id === this.currentUrl || item.id === this.herachyUrl(this.currentUrl) ;
  }

  refresh(){
    this.treeModel  = this.treeComponent.treeModel;
    this.setCurrentNavbar()
  }

  stateChange(event = null): void {
    const tree = event || this.treeComponent;
    if (this.windowsInnerHeight > this.SMALL_HEIGHT_RESOLUTION) {
      this.displayStates = true;
      return;
    }

    if (this.windowsInnerHeight < this.EXTRA_SMALL_HEIGHT_RESOLUTION) {
      this.displayStates = false;
      return;
    }

    if (tree?.expandedNodeIds && Object.keys(tree.expandedNodeIds).length) {
      const status = [];
      Object.keys(tree.expandedNodeIds).forEach((key) => {
          status.push(tree.expandedNodeIds[key]);
      });
      this.displayStates = !status.some(value => value);
    } else {
      this.displayStates = true;
    }
  }

  updateData(event){
    this.refresh();
  }

  changeFilter($event){
  }

  get isRetail(){ return this.utilService.customerIsRetail}
  get isCorporative() { return this.navbarService.getStatus() === 3 }

  collapseSidenav() {
    if (!this.isCollapsing) {
      this.isCollapsing = true;
      this.sideBarCollapsed = !this.sideBarCollapsed;
      this.utilService.toggleNavbar(this.sideBarCollapsed);

      if (this.collapseSidenav) {
        this.treeModel.collapseAll();
      }
      this.setShowStates();
    }
  }

  setStatus(value: number): void {
    this.updateStatus(value);
    this.sideBarCollapsed = false;
    this.utilService.toggleNavbar(this.sideBarCollapsed);
    this.treeModel.collapseAll();
    this.setShowStates();
  }

  initListeners() {
    this.subscriptions.add(
      this.chainOtasErrors$.subscribe((chainOtasErrors) => {
        this.chainOtasErrors = chainOtasErrors;
        this.loadingOtaStatus = false;
      }),
    );

    this.subscriptions.add(
      this.menuState$.subscribe((menus) => {
        this.showIntegrationState = menus.sidebar.some((menu) => menu.slug === 'status_integrations');
      }),
    );

    this.subscriptions.add(
      this.utilService.isCustomerChanged().subscribe(() => {
        this.loadMenu();
        this.loadOtasErrors();
      }),
    );

    this.subscriptions.add(
      this.navbarService.isStatusChanged().subscribe(_ => {
        this.loadMenu();
      }),
    );

    const isCustomerChangedSubs = this.utilService.isCustomerChanged().subscribe(hotel => {
      this.navbarService.setStatus(0);
      const customerId = hotel.id.toString();

      if(this.utilService.getAferCustomerId() !== customerId){
        this.notificationsService.deleteResponseAlert();
        this.adminAlertsService.getAlert(customerId,
          this.utilService.getLanguageId()).subscribe(response => {
              this.notificationsService.setResponseAlert(response);
        });

        this.utilService.setAfterCustomerId(customerId);
      }
      this.updateCustomerLogo(hotel);
      this.utilService.toggleNavbar(this.sideBarCollapsed);
    });
    this.subscriptions.add(isCustomerChangedSubs);

    const isLanguageChangedSubs = this.utilService.isLanguageChanged().subscribe(language =>{
      this.adminAlertsService.getAlert(this.utilService.getAferCustomerId(),
        this.utilService.getLanguageId()).subscribe(response => {
            this.notificationsService.setResponseAlert(response);
      });
    });
    this.subscriptions.add(isLanguageChangedSubs);

    const isStatusChangedSubs = this.navbarService.isStatusChanged().subscribe(response => {
      setTimeout(_ => {
        if (this.sideBarCollapsed) {
          this.sideBarCollapsed = false;
          this.utilService.toggleNavbar(this.sideBarCollapsed);
        }
        this.statusNavbar = response;
      }, 400)
    });

    this.subscriptions.add(isStatusChangedSubs);

    const isToggleNavbarSubs = this.utilService.isToggleNavbar().subscribe((value) => {
      this.sideBarCollapsed = value;
      this.setShowStates();
    });
    this.subscriptions.add(isToggleNavbarSubs);

    const isNavbarChangedSubs = this.utilService.isNavbarChanged().subscribe(() => {
      this.loadMenu();
      this.refresh();
    });
    this.subscriptions.add(isNavbarChangedSubs);
  }

  updateCustomerReady(hotel,emit=true){
    this.utilService.customerChanged(hotel,emit);
  }

  async loadOtasErrors() {
    this.otasErrors = null;
    const response = await this.adminCustomerService.getOtasErrors(this.currentCustomer.id).toPromise();
    if (response?.length > 0) {
      this.showOtasErrors = response.some((o) => o.error.public_error);
      // Dejar esta lógica hasta que se vuelva a evualuar en caso de cualquier cosa
      //this.otasErrors = ((this.showOtasErrors && this.currentUser.admin) || this.currentUser.superAdmin) ? response : null;
      this.otasErrors = response;
    }
  }

  get currentCustomer() {
    return this.utilService.getCurrentCustomer();
  }

  get customerId() {
    return this.utilService.getCurrentCustomer().id;
  }

  get statusState() {
    return this.sideBarCollapsed ? 'collapse' : 'expand';
  }

  setShowStates() {
    const msTime = this.sideBarCollapsed ? 0 : 400;
    setTimeout(() => {
      this.showStates = !this.sideBarCollapsed;
      this.isCollapsing = false;
    }, msTime);
  }

  get windowsInnerWidth() {
    return window.innerWidth;
  }

  get windowsInnerHeight() {
    return window.innerHeight;
  }

  get marginTopStatus() {
    if (this.windowsInnerHeight <= this.SMALL_HEIGHT_RESOLUTION) return '40';
    if (this.windowsInnerHeight <= this.MEDIUM_HEIGHT_RESOLUTION) return '80';
    return '193';
  }

  get paddingTop() {
    if (this.isMobile) return '37';
    return this.windowsInnerHeight <= this.SMALL_HEIGHT_RESOLUTION || this.isCorporative || this.statusNavbar !== 0 ? '0' : '37';
  }

  openOtaStatusModal() {
    this.modal = this.modalService.open(this.statusOTA, {
      size: 'md'
    });
  }

  openPMSStatusModal() {
    this.modalPMS = this.modalService.open(this.statusPMS, {
      size: 'md'
    });
  }

  getTranslateTypeError(ota: OtasErrors) {
    return `admin.otas.error.${ota.error.public_error ? 'public' : 'internal'}`;
  }

  get currentLanguage() {
    return this.utilService.getCurrentLanguage();
  }

  get manualLink() {
    return MANUAL_GOOGLE_FB.find(m => m.language === this.currentLanguage)?.link;
  }

  getAllNotMobileIDs(node: NavbarNode) {
    let ids: number[] = [];
    if (node.children.length) {
      node.children.forEach((childNode: NavbarNode) => {
        if (!childNode.mobile) {
          ids.push(childNode.id);
        }
        ids = ids.concat(this.getAllNotMobileIDs(childNode));
      });
    }
    return ids;
  }

  checkMobileSubItems() {
    if (this.treeModel) {
      (this.treeModel.nodes.length === 1 ? this.treeModel.nodes[0].childrens : this.treeModel.nodes).forEach(node => {
        const mobileNodesIDs = this.getAllNotMobileIDs(node);
        if (mobileNodesIDs.length) {
          mobileNodesIDs.forEach(id => {
            const foundNode = this.treeModel.getNodeById(id);
            if (this.isMobile) {
              foundNode.hide();
            } else {
              foundNode.show();
            }
          });
        }
      });
    }
  }

  get relativeCurrentChain() {
    return this.store.selectSnapshot(CustomersState.relativeCurrentChain);
  }

  get menuContext() {
    return this.statusNavbar === 3 ? 'corporative' : 'general';
  }
}
