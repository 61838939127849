import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { animation } from '../utils/animation-custom';
import { NotificationsService } from 'app/shared/notifications/services/notifications.service';
import { Observable, Subscription } from 'rxjs';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Select } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CoreLibNotificationsPopupModalComponent } from 'app/core-lib/dialogs/notifications-popup-modal/notifications-popup-modal.component';
import { UtilService } from '../util.service';

@Component({
  selector: 'mh-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
  animations: [animation.rotate],
})
export class HeaderButtonComponent implements OnInit, OnDestroy {
  @Select(DefaultLanguageState) languageReady$: Observable<{ code: string; languageId: number }>;

  @Input()
  type: 'searcher' | 'cases' | 'support' | 'languages' | 'notifications' | 'menu' = 'searcher';

  @Input()
  currentUser;

  @Input()
  userProfile;

  @Input()
  isRetail = false;

  @Input()
  customer;

  @Input()
  currentLanguage;

  @Input()
  currentLanguageId;

  @Input()
  availableLanguages = [];

  @Input()
  size = 40;

  @Input()
  isMobile = false;

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  isReady: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  gaEvent = new EventEmitter<any>();

  @Output()
  updatedLanguage = new EventEmitter<any>();

  @Output()
  createCaseEvent = new EventEmitter<any>();

  @Output()
  openMenuEvent = new EventEmitter<any>();
  utilService = inject(UtilService);

  @ViewChild('triggerMenu')
  menu: MatMenuTrigger;

  private showingInput = false;
  activeLanguageBtn = false;
  activeSupportbtn = false;
  activeUserBtn = false;
  activeNotiBtn = false;
  searcherMenuOpened = false;
  isOpen = false;
  newsData;
  alertsData;
  subscriptions = new Subscription();
  notificationComponentType = 'menu';
  hasMoreDataAlerts = false;
  hasMoreDataNews = false;
  pageSizeNews = 10;
  newsUrl: string;
  alertsUrl: string;
  languageId;
  obsLangId;

  constructor(private notificationsService: NotificationsService, private modalService: ModalService) {}

  ngOnInit(): void {
    if (this.isNotifications) {
      this.newsUrl = `${environment.notificationsPath}notification-news/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
      this.alertsUrl = `${environment.notificationsPath}notification-alerts/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
      this.initLanguageSubscriber();
    }
  }

  initPolling() {
    this.subscriptions.add(
      this.notificationsService.initPolling(this.alertsUrl, { page: 0 }).subscribe((res) => {
        if (res && res.info) {
          this.alertsData = res.info;
          this.hasMoreDataAlerts = res.has_next;
        }
      }),
    );
  }

  async getNews() {
    try {
      this.newsData = await this.notificationsService.getNotificationsData({ page: 0 }, this.newsUrl).toPromise();
    } catch (error) {
      this.newsData = [];
      console.error(error);
    }
  }

  async handleShowMore(type: string) {
    const url = type === 'alert' ? this.alertsUrl : this.newsUrl;
    if (type === 'alert') {
      this.notificationsService.pollingSize += 10;
    } else {
      this.pageSizeNews += 10;
    }
    const pageSize = type === 'alert' ? this.notificationsService.pollingSize : this.pageSizeNews;

    try {
      const res = await this.notificationsService.getNotificationsData({ page: 0, pageSize }, url).toPromise();
      if (res && res.info) {
        if (type === 'alert') {
          this.alertsData = res.info;
          this.hasMoreDataAlerts = res.has_next;
        } else {
          this.newsData = res.info;
          this.hasMoreDataNews = res.has_next;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCommunications(isAlert: boolean) {
    try {
      const url = isAlert ? this.alertsUrl : this.newsUrl;
      const pageSize = isAlert ? this.notificationsService.pollingSize : this.pageSizeNews;
      const res = await this.notificationsService.getNotificationsData({ page: 0, pageSize }, url).toPromise();
      if (res && res.info) {
        if (isAlert) {
          this.alertsData = res.info;
          this.hasMoreDataAlerts = res.has_next;
        } else {
          this.newsData = res.info;
          this.hasMoreDataNews = res.has_next;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  initLanguageSubscriber() {
    this.subscriptions.add(
      this.languageReady$.subscribe(async (languageCode) => {
        if (
          languageCode.languageId &&
          this.languageId !== languageCode.languageId &&
          this.obsLangId !== languageCode.languageId
        ) {
          this.obsLangId = languageCode.languageId;
          this.languageId = languageCode.languageId;
          this.currentLanguageId = languageCode.languageId;
          this.newsUrl = `${environment.notificationsPath}notification-news/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
          this.alertsUrl = `${environment.notificationsPath}notification-alerts/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
          this.destroyPolling();
          this.initPolling();
          await this.getCommunications(false);
          this.openNewsPopup(); // Call the openNewsPopup method when news data is available
        }
      }),
    );

    this.subscriptions.add(
      this.notificationsService.listenUpdateOnNews().subscribe(() => {
        this.getCommunications(false);
      }),
    );
  }

  toggleShowInput(origin: string) {    
    this.showingInput = !this.showingInput;
    if (origin) {
      this.searcherMenuOpened = false;
    }
  }

  toggleRotateIcon() {
    this.showingInput = !this.showingInput;
  }

  setFocusSearchInput() {
    this.searcherMenuOpened = true;
  }

  toggleMenu() {}

  updateLanguage($event) {
    this.updatedLanguage.emit($event);
  }

  customerUpdated($event) {
    this.menu.closeMenu();
    this.updated.emit($event);
  }

  readyToEmit($event) {
    this.isReady.emit($event);
  }

  ga($event) {
    this.gaEvent.emit($event);
  }

  createCase() {
    this.utilService.ga('',' new-case-header');
    this.createCaseEvent.emit(true);
  }

  openSideMenu() {
    this.openMenuEvent.emit(true);
  }

  handleHistoryBtn() {
    this.isOpen = !this.isOpen;
    this.activeNotiBtn = !this.activeNotiBtn;
  }

  destroyPolling() {
    this.notificationsService.destroyPolling();
  }

  clickCNBtn() {
    this.activeNotiBtn = !this.activeNotiBtn;
    this.isOpen = !this.isOpen;
    this.notificationsService.pollingSize = 10;
    this.pageSizeNews = 10;
    if (this.alertsData?.length > 10) {
      this.alertsData.splice(10);
      this.hasMoreDataAlerts = true;
    }

    if (this.newsData?.length > 10) {
      this.newsData.splice(10);
      this.hasMoreDataNews = true;
    }
  }

  async openNewsPopup() {
    const popupData = this.newsData.filter(
      (newData) => newData.notifications_type === 'POPUP' && newData.notifications_status === 'UNREAD',
    );
    if (popupData.length === 0) return;

    const classes = ['lg', 'overlay-panel', 'fit-height-content', '!tw-p-0'];
    const config = {
      data: {
        noCloseOnBackdropClick: true,
        timer: true,
        news: popupData,
        currentUser: this.currentUser,
        currentCustomerId: this.customer.id,
      },
    };
    this.modalService.open(CoreLibNotificationsPopupModalComponent, config, classes);
  }

  ngOnDestroy(): void {
    this.destroyPolling();
    this.notificationsService.pollingSize = 10;
    this.pageSizeNews = 10;
    this.subscriptions.unsubscribe();
  }

  get mobileClassSearcher() {
    return this.isMobile ? 'mobile' : '';
  }

  get showInput() {
    return this.showingInput;
  }

  get rotateAnimationState() {
    return !this.showingInput ? '0' : '-180';
  }

  get isSearcher() {
    return this.type === 'searcher';
  }

  get isCases() {
    return this.type === 'cases';
  }

  get isLanguages() {
    return this.type === 'languages';
  }

  get isSupport() {
    return this.type === 'support';
  }

  get isNotifications() {
    return this.type === 'notifications';
  }

  get isMenu() {
    return this.type === 'menu';
  }

  get sizeBtn() {
    return {
      'width.px': `${this.size}`,
      'height.px': `${this.size}`,
    };
  }

  isActive(language: any) {
    return language === this.currentLanguage;
  }

  get linkSupport() {
    const links = {
      es: 'https://soporte.myhotel.cl/es/base-conocimientos',
      en: 'https://soporte.myhotel.cl/en/base-conocimientos',
      pt: 'https://soporte.myhotel.cl/pt-br/base-conocimientos',
    };
    return links[this.currentLanguage];
  }
}
